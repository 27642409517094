import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.as || 'button'), {
    type: "button",
    class: ["\n      inline-flex\n      font-body\n      text-lg\n      leading-8\n      px-5\n      py-2\n      lg:text-2xl\n      lg:px-8\n      lg:py-4\n      rounded-full\n      cursor-pointer\n      items-center\n      transition-all\n      select-none\n    ", {
      'bg-amff-black text-amff-white lg:hover:text-amff-light-gray':
        _ctx.color === 'amff-black',
      'bg-amff-teal text-amff-navi lg:hover:bg-amff-light-teal':
        _ctx.color === 'amff-teal' && !_ctx.isDisabled,
      'bg-amff-light-teal text-amff-white lg:hover:bg-amff-light-teal':
        _ctx.color === 'amff-pink' && _ctx.isDisabled,
      'bg-filmin-green text-amff-black lg:hover:bg-amff-gray':
        _ctx.color === 'filmin-green',
      'text-amff-teal lg:hover:text-amff-light-teal':
        _ctx.color === 'amff-teal-text',
      'text-amff-gray lg:hover:text-amff-light-gray':
        _ctx.color === 'amff-gray-text',
      'text-amff-black-2024 lg:hover:text-amff-gray':
        _ctx.color === 'amff-black-text',
      'text-amff-black-2024 underline underline-offset-4':
        _ctx.color === 'amff-black-text-underlined',
      'text-amff-white lg:hover:text-amff-teal': _ctx.color === 'amff-white-text',
      'text-amff-teal border-2 border-amff-teal lg:hover:bg-amff-teal lg:hover:text-amff-white':
        _ctx.color === 'amff-teal-outline',
      'text-amff-black border-2 border-amff-black-2024 lg:hover:bg-amff-black-2024 lg:hover:text-amff-white':
        _ctx.color === 'amff-black-outline',
      'text-amff-white  bg-amff-black-2024 lg:hover:bg-amff-yellow-2024 lg:hover:text-amff-black-2024':
        _ctx.color === 'amff-yellow',
      'text-amff-black-2024  bg-amff-white lg:hover:bg-amff-yellow-2024 ':
        _ctx.color === 'amff-white',
    }],
    disabled: _ctx.isDisabled
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["disabled", "class"]))
}