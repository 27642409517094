import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "\n      fixed\n      inset-0\n      z-50\n      bg-amff-teal\n      text-amff-black\n      overflow-y-auto\n      flex\n      lg:pt-36\n    " }
const _hoisted_2 = { class: "my-6 lg:mb-14 max-w-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AMFFLogo = _resolveComponent("AMFFLogo")
  const _component_PageTitle = _resolveComponent("PageTitle")
  const _component_Button = _resolveComponent("Button")
  const _component_PageContainer = _resolveComponent("PageContainer")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_PageContainer, { class: "relative z-10 text-center" }, {
      default: _withCtx(() => [
        _createVNode(_component_AMFFLogo, {
          class: "w-52 inline-block",
          withoutIcon: ""
        }),
        _createVNode(_component_PageTitle, { class: "mt-10 lg:mt-14" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("error.are_you_lost")), 1)
          ]),
          _: 1
        }),
        _createVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("error.calm_down_if_youre_lost")), 1),
        _createVNode(_component_Button, {
          as: _ctx.AnchorLink,
          to: { name: 'home' }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("error.take_me_home")), 1)
          ]),
          _: 1
        }, 8, ["as"])
      ]),
      _: 1
    })
  ]))
}