
import { defineComponent } from "vue";

const isValidColor = (value: string) =>
  [
    "amff-black",
    "amff-teal",
    "amff-teal-outline",
    "amff-teal-text",
    "amff-gray-text",
    "amff-white-text",
    "filmin-green",
    "amff-black-outline",
    "amff-yellow",
    "amff-white",
    "amff-black-text-underlined",
    "amff-black-text",
  ].includes(value);

export default defineComponent({
  props: {
    as: { type: [String, Object] },
    color: { type: String, default: "amff-black", validator: isValidColor },
    isDisabled: { type: Boolean },
  },
  setup() {
    return {};
  },
});
