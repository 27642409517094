
import { defineComponent, onBeforeUnmount, onMounted } from "vue";
import PageContainer from "@/components/PageContainer.vue";
import PageTitle from "@/components/PageTitle.vue";
import AMFFLogo from "@/components/AMFFLogo.vue";
import AnchorLink from "@/components/AnchorLink.vue";
import Button from "@/components/Button.vue";

export default defineComponent({
  components: {
    PageContainer,
    PageTitle,
    AMFFLogo,
    Button,
  },
  setup() {
    onMounted(() => {
      document.body.style.overflow = "hidden";
    });
    onBeforeUnmount(() => {
      document.body.style.overflow = "";
    });
    return {
      AnchorLink,
      baseURL: process.env.BASE_URL,
    };
  },
});
