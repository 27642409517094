<template>
  <div class="PageContainer px-container max-w-3xl mx-auto py-20">
    <slot />
  </div>
</template>

<style>
.PageContainer .PageTitle + .PageContent {
  @apply mt-6 lg:mt-16;
}
</style>
